import { Grid } from 'ag-grid-community';

;(function () {

  "use strict";
  var SequenceIndex = function () {
    var $toggleButton = $('#toggle-filter');
    var $filterForm = $('#filter-form');
    
    var initializeTable = function() {
    const sequencesDataElement = document.getElementById('sequences-data');

     const rowData = JSON.parse(sequencesDataElement.dataset.json);

      const columnDefs = [
        {
          headerName: 'Actions',
          field: 'id',
          width: '100',
          cellRenderer: function(params) {
            const viewLink = `<a href="/v2/sequences/${params.value}">View</a>`;
            const editLink = `<a href="/v2/sequences/${params.value}/edit">Edit</a>`;
            const deleteLink = `<a href="/v2/sequences/${params.value}" data-method="delete" data-confirm="Are you sure?">Delete</a>`;
            return `${viewLink}`;
          },
          resizable: true
        },
        { headerName: 'Texts', field: 'sequence_text', resizable: true, enableRangeSelection: true, 
          cellRenderer: function(params) {
            const viewLink = `<a href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_text", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
        },
        { headerName: 'Creations', field: 'sequence_creation', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_creation", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
        },
        { headerName: 'Completes', field: 'sequence_complete', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_complete", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
         },
        { headerName: 'Lexigrams', field: 'sequence_lexigram', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_lexigram", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
         },
        { headerName: 'Singulars', field: 'sequence_singular', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_singular", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
         },
        { headerName: 'Lenses', field: 'sequence_lense', resizable: true, enableRangeSelection: true,
          cellRenderer: function(params) {
            const viewLink = `<a href="/v2/sequences?search_text=${params.value}&match_location=exact_location&search_columns%5B%5D=sequence_lense", target: '_blank' >${params.value}</a>`;
            return `${viewLink}`;
          },
         },
        { headerName: 'Description', field: 'description', resizable: true, enableRangeSelection: true},
        { headerName: 'Url', field: 'url', resizable: true },
        { headerName: 'Reference', field: 'reference', resizable: true }
      ]

      const gridOptions = {
        columnDefs: columnDefs,
        rowData: rowData,
        
        gridOptions: {
          enableRangeSelection: true,
          clipboard: {
            suppressLastEmptyLine: true
          }
        }
      };
  
      const eGridDiv = document.querySelector('#myGrid');
      new Grid(eGridDiv, gridOptions);
  
      const styleElements = document.querySelectorAll('style');
      styleElements.forEach((style) => {
        style.setAttribute('nonce', nonce);
      });
    }

    var initializeFilterToggle = function() {
      $toggleButton.on('click', function() {
        $filterForm.slideToggle();
      });
    }


    this.init = function() {
      initializeFilterToggle();
      initializeTable();
    }
  };

  $(document).ready(function () {
  if ($('body').data('controller') === 'sequences' && ($('body').data('action') === 'advanced_search') || ($('body').data('action') === 'index')) {
      var sequenceIndex = new SequenceIndex();
      sequenceIndex.init();
    }
  });
})();